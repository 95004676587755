import Title from '../../components/Title/Title';
import Container from '@mui/material/Container';
import BodyText from '../BodyText/BodyText';
import Swiper from '../SwiperLogo/SwiperLogo';
import './AboutCompany.css';
import { motion } from 'framer-motion';

const blockAnimationLeft = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};
const blockAnimationRight = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

function AboutCompany() {
  return (
    <Container>
      <div className="AboutCompany">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={blockAnimationLeft}
          viewport={{ amount: 0.4, once: true }}
          className="AboutCompany__title"
        >
          <Title title="О компании" h2="h2" xStart={-100} />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={blockAnimationRight}
          viewport={{ amount: 0.4, once: true }}
          className="AboutCompany__swiper"
        >
          <Swiper></Swiper>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={blockAnimationLeft}
          viewport={{ amount: 0.4, once: true }}
        >
          <Title title="ООО «ДИОЛ Логистик»" h3="h3" xStart={-100} />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={blockAnimationLeft}
          viewport={{ amount: 0.4, once: true }}
        >
          <BodyText text="Компания ООО «ДИОЛ Логистик» основана в феврале 2012 года." />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={blockAnimationLeft}
          viewport={{ amount: 0.4, once: true }}
        >
          <BodyText text="Компания «ДИОЛ Логистик» основана в 2020 году. Основное направление деятельности транспортно-экспедиционные услуги, услуги складской логистики и хранение грузов." />
          <br></br>
          <BodyText
            text="Автопарк включает в себя новые автомобили марки МАН, шторные полуприцепы объемом 92 см3, автоцистерны объемом 30см3, полуприцепы – площадки для перевозки танк – контейнеров и 20 футовых контейнеров.
Также парк оснащен собственными танк – контейнерами объемом 26дм3."
          />
          <br></br>
          <BodyText text="Весь автопарк оборудован системами контроля, что дает возможность получать данные о перевозимых грузах в режиме реального времени и гарантировать доставку качественно и в срок." />
        </motion.div>
      </div>
    </Container>
  );
}

export default AboutCompany;
