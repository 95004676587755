import { Container } from '@mui/material';
import Title from '../Title/Title';
import BodyText from '../BodyText/BodyText';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import './StagesFormation.css';
import Divider from '@mui/material/Divider';
import Customer from '../Сustomer/Сustomer';
import { motion } from 'framer-motion';

const blockAnimationLeft = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};
const blockAnimationRight = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export default function StagesFormation() {
  return (
    <Container>
      <div className="StagesFormation">
        <div className="StagesFormation__description">
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={blockAnimationLeft}
            viewport={{ amount: 0.4, once: true }}
          >
            <Title title="Становление компании" h2="h2" />
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={blockAnimationLeft}
            viewport={{ amount: 0.4, once: true }}
            className="StagesFormation__stage"
          >
            <div className="">
              <Title title="2012 год" h3="h3" />
              <BodyText text=" Организация собственного автопарка грузовых машин позволяющего максимально быстро поставлять и гарантировать заказчикам качество продукции минуя третьих лиц." />
            </div>
            <HandshakeTwoToneIcon className="StagesFormation__Icon" />
          </motion.div>
          <Divider />
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={blockAnimationRight}
            viewport={{ amount: 0.4, once: true }}
            className="StagesFormation__stage"
          >
            <LocalShippingTwoToneIcon className="StagesFormation__Icon" />
            <div className="">
              <Title title="2014 год" h3="h3" />
              <BodyText text="В структуре компании произошли масштабные изменения: DIOL Logistik сосредоточилась на стратегическом подходе и внедрила долгосрочную политику развития компании. Опираясь на потребности рынка и внутреннюю стратегию, компания показала стремительный рост." />
            </div>
          </motion.div>
          <Divider />
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={blockAnimationLeft}
            viewport={{ amount: 0.4, once: true }}
            className="StagesFormation__stage"
          >
            <div className="">
              <Title title="2016-2017 годы" h3="h3" />
              <BodyText text="Ппарк грузоперевозчика прирос более современной единиц техники. Что открыло новые возможности." />
            </div>
            <CategoryTwoToneIcon className="StagesFormation__Icon" />
          </motion.div>

          <Divider />
          <Customer />
        </div>
      </div>
    </Container>
  );
}
